.wrapper-footer{
	background-color: #000;
	box-shadow: 0 -2px 4px rgba(255, 255, 255, 0.2);
	
	.footer{
		@include width1000;
		@include clearfix;
		padding: 10px 0 10px 0;
		
		ul{
			li{
				float: left;
				padding: 6px 10px;
				
				a{
					font-size: 12px;
					color: #9C9C9C;
					
					&:hover{
						color: orange;
					}
					
					@include respond-to(handhelds){
						font-size: 11px;
					}
				}
				
				@include respond-to(handhelds){
					padding: 6px 8px;
				}
			}
			span{
				float: left;
				display: block;
				width: 1px;
				height: 20px;
				margin-top: 4px;
				background-color: #aaa;
			}
		}
		.links{
			float: right;
			padding: 5px 10px;
			
			@include respond-to(wide-handhelds){
				float: none;
				clear: both;
			}	
			@include respond-to(handhelds){
				float: none;
				clear: both;
			}
		}
		
		@include respond-to(wide-handhelds){
			padding: 10px 0;
		}	
		@include respond-to(handhelds){
			padding: 10px 0;
		}
	}
	
	@include respond-to(handhelds){
		width: 100%;
	}
	@include respond-to(wide-handhelds){
		width: 100%;
	}
	@include respond-to(small-tablets){
		width: 100%;
	}
	@include respond-to(tablets){
		width: 100%;
	}
}